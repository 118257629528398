import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//components
import DeviceImg from "../../assets/images/devices.png";
import Iconify from "../../components/iconify/Iconify";
import Preview from "../../layout/Preview";
import MeasurementList from "../Dashboard/components/MeasurementList";
import DiComViewer from "../ManageDiagnostics/DiComViewer";
import "./AlarmHistoryTabPanel.css"; // Importing a CSS file for styling
import DicomViewer from "./DiComViewer";
import DiComViewerComponent from "./DiComViewerComponent";

//mui-components
import { Close, Home } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { ThemeProvider, createTheme, emphasize, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// DICOM Viewer import
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

//bootstrap-components
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

//externals
import { Icon } from "@iconify/react";
import Chart from "chart.js/auto";
import { format } from "date-fns";
import moment from "moment";
import "react-image-gallery/styles/css/image-gallery.css";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

//services
import ApiCalls from "../../services/try";

// ----------------------------------------------------------------------

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" sx={{ p: 3 }}>
          {children}
        </Typography>
      )}
    </div>
  );
}

// ----------------------------------------------------------------------

const PatientView = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

  const [tabValue, setTabValue] = useState(0);

  const [filterOptions, setFilterOptions] = useState([
    {
      value: "today",
      label: "Today",
    },
    {
      value: "week",
      label: "One Week",
    },
    {
      value: "month",
      label: "One Month",
    },
    {
      value: "all",
      label: "All",
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);

  const [limitfilterOptions, setlimitFilterOptions] = useState([
    {
      value: 100,
      label: "100 Rows",
    },
    {
      value: 1000,
      label: "1000 Rows",
    },
    {
      value: 10000,
      label: "10000 Rows",
    },
  ]);

  const [durationFilterOptions, setDurationFilterOptions] = useState([
    {
      value: 1,
      label: "1 hr",
    },
    {
      value: 2,
      label: "2 hrs",
    },
    {
      value: 3,
      label: "3 hrs",
    },
    {
      value: 4,
      label: "4 hrs",
    },
    {
      value: 5,
      label: "5 hrs",
    },
    {
      value: 6,
      label: "6 hrs",
    },
    {
      value: 7,
      label: "7 hrs",
    },
  ]);

  const [patientId, setPatientId] = useState("");
  const [hmrNo, setHmrNo] = useState("");
  const [vitalParamName, setVitalParamName] = useState("");
  const [waveformParamName, setWaveformParamName] = useState("");
  const [alarmParamterName, setAlarmParametername] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [scanType, setScanType] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [bodySite, setBodySite] = useState("");
  const [error, setError] = useState("");
  const [filePath, setFilePath] = useState("");
  const [patientTrend, setPatientTrend] = useState("false");
  const [deviceId, setDeviceId] = useState("");
  const [imageType, setImageType] = useState("standard");

  const [selectedLimitFilter, setSelectedLimitFilter] = useState(
    limitfilterOptions[0]
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions[0]
  );
  const [selectedDurationFilter, setSelectedDurationFilter] = useState(
    durationFilterOptions[0]
  );

  const [patientDetails, setPatientDetails] = useState([]);
  const [physicianDetails, setPhysicianDetails] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [vitalMeasurements, setVitalMeasurements] = useState([]);
  const [waveformMeasurements, setWaveformMeasurements] = useState([]);
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [selectedAlarmParamFilter, setSelectedAlarmParamFilter] = useState([]);
  const [selectedWaveParamFilter, setSelectedWaveParamFilter] = useState([]);
  const [vitalParameters, setVitalParameters] = useState([]);
  const [waveformParameters, setWaveformParameters] = useState([]);
  const [alarmParameters, setAlarmParameters] = useState([]);
  const [images, setImages] = useState([]);
  const [patientHistory, setPatientHistory] = useState([]);
  const [alarmHistory, setAlarmHistory] = useState([]);
  const [patients, setPatients] = useState([]);
  const [seriesImages, setSeriesImages] = useState([]);

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [openAlarmParamFilter, setOpenAlarmParamFilter] = useState(null);
  const [openWaveParamFilter, setOpenWaveParamFilter] = useState(null);
  const [openLimitFilter, setOpenLimitFilter] = useState(null);
  const [openDurationFilter, setOpenDurationFilter] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [dicomFile, setDicomFile] = useState(null);

  const [loadPatientDetails, setLoadPatientDetails] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);
  const [waveLoading, setWaveLoading] = useState(false);
  const [vitalLoading, setVitalLoading] = useState(false);

  const [loadingAlarmHistory, setLoadingAlarmHistory] = useState(true);
  const [endDateSelected, setEndDateSelected] = useState(false); // Track if end date is selected
  const [openDialog, setOpenDialog] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [patientIdError, setPatientIdError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const [filePathError, setFilePathError] = useState(false);
  const [openImageViewDialog, setOpenImageViewDialog] = useState(false);
  const [openDiComViewer, setOpenDiComViewer] = useState(false);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);

  const [image, setImage] = useState(null);


  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const chartRefreshInterval = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);

  const formatDate = (date) => {
    return format(new Date(date), "MM/dd/yyyy");
  };

  const maxDate = formatDate(new Date());

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedAlarms = alarmHistory.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpenAlarmParamFilters = (e) => {
    setOpenAlarmParamFilter(e.currentTarget);
  };

  const handleCloseAlarmParamFilters = () => {
    setOpenAlarmParamFilter(null);
  };

  useEffect(() => {
    var roleId = secureLocalStorage.getItem("roleId");

    ApiCalls.getdetails(
      "roleId/" + roleId + "/getpatientprivilegedetails"
    ).then((response) => {
      console.log(response.data);
      if (response.data.patientprivilege != undefined) {
        const patientTrend = response.data.patientprivilege[0].patientTrend;
        console.log("patientTrend: " + patientTrend);
        setPatientTrend(patientTrend);
      }
    });
  }, []);

  const loadAllPatientImages = () => {
    setImgLoading(true);
    let tenantId = secureLocalStorage.getItem("tenantId");
    let companyId = secureLocalStorage.getItem("compId");
    let physicianId = "";
    const rolename = secureLocalStorage.getItem("rolename");
    const patientid = secureLocalStorage.getItem("PatientToView");

    if (rolename === "Tenant") {
      tenantId = secureLocalStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("UUID");
      physicianId = "0";
    } else if (rolename === "Physician") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("compId");
      physicianId = secureLocalStorage.getItem("UUID");
    }

    ApiCalls.getdetails(
      `tenant/${tenantId}/company/${companyId}/physician/${physicianId}/patient/${patientid}/displayUploadedImages`
    )
      .then((response) => {
        console.log("API response:", response.data); // Check data structure
        if (response.data.diagnosticImage) {
          // Ensure the correct field is being used
          setPatients(response.data.diagnosticImage);
          console.log("Patient data set:", response.data.diagnosticImage); // Ensure data is set
        } else {
          setPatients([]);
        }
        setImgLoading(false);

        // Filter and load DICOM files
        const dicomFiles = response.data.diagnosticImage.filter(
          (img) => img.imageType === "dicom"
        );
        loadDicomImages(dicomFiles);
      })
      .catch((error) => {
        console.log("API call error:", error);
        setImgLoading(false);
        setPatients([]);
      });
  };

  // const loadDicomImages = (dicomFiles) => {
  //   const promises = dicomFiles.map((file) =>
  //     ApiCalls.viewFile("viewDicomImage", { filePath: file.filePath })
  //   );

  //   Promise.all(promises)
  //     .then((responses) => {
  //       const dicomBlobs = responses.map((response) => {
  //         if (response.data instanceof Blob) {
  //           return response.data;
  //         }
  //         return null;
  //       }).filter(blob => blob !== null);

  //       setSeriesImages(dicomBlobs);
  //       console.log("Loaded DICOM blobs:", dicomBlobs); // Verify blobs
  //     })
  //     .catch((error) => {
  //       console.error("Error loading DICOM images:", error);
  //     });
  // };

  const loadDicomImages = (dicomFiles) => {
    const promises = dicomFiles.map((file) =>
      ApiCalls.viewFile("viewDicomImage", { filePath: file.filePath })
        .then(response => ({
          imageBlob: response.data instanceof Blob ? response.data : null,
          details: {
            scanType: file.scanType,
            bodyPart: file.bodySite,
            description: file.imageDescription,
            dateAndTime: file.scanDate,
          }
        }))
    );

    Promise.all(promises)
      .then((dicomData) => {
        const validDicomData = dicomData.filter(data => data.imageBlob !== null);
        setSeriesImages(validDicomData);
        console.log("Loaded DICOM data with details:", validDicomData); // Verify structured data
      })
      .catch((error) => {
        console.error("Error loading DICOM images:", error);
      });
  };

  const loadPatientDetailsById = (patientId) => {
    setLoadPatientDetails(true);
    ApiCalls.getdetails("getpatient/" + patientId + "/patients").then(
      (response) => {
        console.log(response.data);
        if (response.data.patient != undefined) {
          setPatientDetails(response.data.patient[0]);
          setLoadPatientDetails(false);
        } else {
          setLoadPatientDetails(false);
          setPatientDetails([]);
        }
      }
    );
  };

  const getWaveformData = (patientId, limit, paramName) => {
    console.log("DeviceId*****" + deviceId);
    console.log("limit*****" + limit);
    console.log("paramName*****" + paramName);

    setWaveLoading(true);
    ApiCalls.getEmbeddedData(
      "patient/" +
      patientId +
      "/parameter/" +
      paramName +
      "/fetchembeddeddata/" +
      limit
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.cms != undefined) {
          setWaveformMeasurements(response.data.cms);
          setWaveLoading(false);
        } else {
          setWaveformMeasurements([]);
          setWaveLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setWaveformMeasurements([]);
        setWaveLoading(false);
      });
  };

  const loadPhysicianDetailsById = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getPhysician").then(
      (response) => {
        console.log(response.data.patient);
        if (response.data.patient !== undefined) {
          setPhysicianDetails(response.data.patient);
        } else {
          setPhysicianDetails([]);
        }
      }
    );
  };

  const loadDeviceDetailsById = (patientId) => {
    ApiCalls.getdetails(
      "patient/" + patientId + "/getDeviceAssignedByBed"
    ).then((response) => {
      console.log(response.data.device);
      if (response.data.device !== undefined) {
        setDeviceDetails(response.data.device);
        setDeviceId(response.data.device[0].randomDeviceId);
      } else {
        setDeviceDetails([]);
      }
    });
  };

  const loadMeasurementDetailsById = (patientId) => {
    setVitalLoading(true);
    const paramName = "No parameter";

    ApiCalls.getdetails(
      "patient/" +
      patientId +
      "/parameter/" +
      paramName +
      "/getmeasurementdetails"
    )
      .then((response) => {
        if (response.data.measurement !== undefined) {
          setVitalMeasurements(response.data.measurement);
          setVitalLoading(false);
        } else {
          setVitalMeasurements([]);
          setVitalLoading(false);
        }
      })
      .catch((error) => {
        setVitalMeasurements([]);
        setVitalLoading(false);
      });
  };

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };

  const handleOpenWaveParamFilters = (e) => {
    setOpenWaveParamFilter(e.currentTarget);
  };

  const handleCloseWaveParamFilters = () => {
    setOpenWaveParamFilter(null);
  };

  const handleOpenDurationFilters = (e) => {
    setOpenDurationFilter(e.currentTarget);
  };

  const handleCloseDurationFilters = () => {
    setOpenDurationFilter(null);
  };

  const handleDurationFilterChange = (filter) => {
    setSelectedDurationFilter(filter);
    chartMeasurements(
      patientId,
      selectedParamFilter.label,
      selectedDateFilter.value,
      filter.value
    );
    handleCloseDurationFilters();
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  };

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  };

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    chartMeasurements(
      patientId,
      selectedParamFilter.label,
      filter.value,
      selectedDurationFilter.value
    );
    handleCloseDateFilters();
  };

  const handleParamFilterChange = (filter) => {
    setSelectedParamFilter(filter);
    chartMeasurements(
      patientId,
      filter.label,
      selectedDateFilter.value,
      selectedDurationFilter.value
    );
    handleCloseParamFilters();
  };

  const handleAlarmParamFilterChange = (filter) => {
    console.log(filter);
    setSelectedAlarmParamFilter(filter);
    // loadAlarmHistory(patientId, { paramName: filter.label, startDate: selectedStartDate, endDate: selectedEndDate });
    handleCloseAlarmParamFilters();
  };

  const handleStartDateChange = (event) => {
    const startDateValue = event.target.value;
    setSelectedStartDate(startDateValue);
    // loadAlarmHistory(patientId, {
    //   paramName: selectedAlarmParamFilter.label,
    //   startDate: startDateValue,
    //   endDate: ''
    // });
  };

  const handleEndDateChange = (event) => {
    const endDateValue = event.target.value;
    setSelectedEndDate(endDateValue);
    setEndDateSelected(true); // Mark end date as selected
    // loadAlarmHistory(patientId, {
    //   paramName: selectedAlarmParamFilter.label,
    //   startDate: selectedStartDate,
    //   endDate: endDateValue
    // });
  };

  const handleWaveParamFilterChange = (filter) => {
    console.log(filter);
    setSelectedWaveParamFilter(filter);
    getWaveformData(patientId, selectedLimitFilter.value, filter.label);
    handleCloseWaveParamFilters();
  };

  const chartMeasurements = async (
    patientId,
    parameterName,
    dateFilter,
    durationFilter
  ) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    let labelData = [];
    let chartData = [];

    try {
      const res = await ApiCalls.getdetails(
        `patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`
      );

      if (!res.data.measurement) {
        chartData = [];
      } else {
        for (const dataObj of res.data.measurement) {
          const date = formatToLocalTime(dataObj.dateOfReading); // Use formatted time with AM/PM
          labelData.push(date);
          chartData.push(dataObj.readingValues);
        }
      }
    } catch (err) {
      console.error(err);
      chartData = [];
    }

    if (chartRef.current) {
      chartRef.current.data.labels =
        labelData.length > 0 ? labelData : ["No data available"];
      chartRef.current.data.datasets =
        chartData.length > 0
          ? [
            {
              label: parameterName + " () Reading Level",
              data: chartData,
              borderColor: "#6ff9ff",
              borderWidth: 1.5,
              tension: 0.5,
              pointStyle: "circle",
              pointRadius: 0,
            },
          ]
          : [
            {
              label: parameterName + " () Reading Level",
              data: [0],
              borderColor: "#6ff9ff",
              borderWidth: 1.5,
              tension: 0.5,
            },
          ];

      chartRef.current.update();
    } else {
      chartRef.current = new Chart(canvas, {
        type: "line",
        data: {
          labels: labelData.length > 0 ? labelData : ["No data available"],
          datasets:
            chartData.length > 0
              ? [
                {
                  label: parameterName + " () Reading Level",
                  data: chartData,
                  borderColor: "#6ff9ff",
                  borderWidth: 1.5,
                  tension: 0.5,
                  pointStyle: "circle",
                  pointRadius: 0,
                },
              ]
              : [
                {
                  label: parameterName + " () Reading Level",
                  data: [0],
                  borderColor: "#6ff9ff",
                  borderWidth: 1.5,
                  tension: 0.5,
                },
              ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true, // Start the Y-axis from zero
            },
          },
        },
      });
    }

    // Clear any existing interval before starting a new one
    if (chartRefreshInterval.current) {
      clearInterval(chartRefreshInterval.current);
    }

    chartRefreshInterval.current = setInterval(async () => {
      const currentTime = moment().format("HH:mm:ss");
      try {
        await chartMeasurements(
          patientId,
          parameterName,
          dateFilter,
          durationFilter
        );
        console.log(`after 5 mins... Current Time: ${currentTime}`);
      } catch (err) {
        console.error(err);
      }
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  const loadWaveformParameters = (patientId) => {
    ApiCalls.getdetails(
      "patient/" + patientId + "/getmeasurementParameter"
    ).then((res) => {
      console.log(res.data);
      if (res.data.cms !== undefined) {
        setWaveformParamName(res.data.cms[0].param_name);
        const options = res.data.cms.map((d) => ({
          label: d.param_name,
          value: d.param_id,
        }));
        const allOption = { label: "All", value: "all" };
        options.unshift(allOption);
        var dateFilter = "today";
        // chartMeasurements(patientId, res.data.cms[0].param_name, dateFilter);
        setWaveformParameters(options);
        setSelectedWaveParamFilter(options[0]);
        getWaveformData(patientId, selectedLimitFilter.value, options[0].value);
      } else {
        setWaveformParamName("");
        setWaveformParameters([]);
      }
    });
  };

  const loadVitalsParameters = (patientId) => {
    ApiCalls.getdetails(
      "patientId/" + patientId + "/getmeasurementparameter"
    ).then((res) => {
      console.log(res.data);
      if (res.data.measure !== undefined) {
        setVitalParamName(res.data.measure[0].paramName);
        const options = res.data.measure.map((d) => ({
          label: d.paramName,
          value: d.paramid,
        }));
        var dateFilter = "today";
        var durationFilter = 1;
        chartMeasurements(
          patientId,
          res.data.measure[0].paramName,
          dateFilter,
          durationFilter
        );
        setVitalParameters(options);
        setSelectedParamFilter(options[0]);
        getWaveformData(patientId, selectedLimitFilter.value, options[0].value);
      } else {
        setVitalParamName("");
        setVitalParameters([]);
      }
    });
  };

  const loadPatientHistory = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/bedById")
      .then((response) => {
        console.log(response.data);
        if (response.data.beds != undefined) {
          console.log(response.data.beds);
          setPatientHistory(response.data.beds);
        } else {
          setPatientHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadAlarmHistory = (patientId, filter = {}) => {
    const { startDate, endDate, paramName } = filter;

    // Format dates to ISO 8601 format with UTC timezone
    const formattedStartDate = startDate
      ? new Date(startDate).toISOString()
      : "";
    const formattedEndDate = endDate ? new Date(endDate).toISOString() : "";

    const queryParams = new URLSearchParams();
    if (formattedStartDate) queryParams.append("startDate", formattedStartDate);
    if (formattedEndDate) queryParams.append("endDate", formattedEndDate);
    if (paramName) queryParams.append("paramName", paramName);

    ApiCalls.getdetails(
      `patient/${patientId}/alarmHistory?${queryParams.toString()}`
    )
      .then((response) => {
        console.log(response.data);
        if (response.data.alarms !== undefined) {
          setAlarmHistory(response.data.alarms);
          setAlarmHistory(response.data.alarms);
        } else {
          setAlarmHistory([]);
          setAlarmHistory([]);
        }
        setLoadingAlarmHistory(false);
      })
      .catch((err) => {
        console.error(err);
        setAlarmHistory([]);
        setLoadingAlarmHistory(false);
      });
  };

  const loadAlarmParameters = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getAlarmParameters")
      .then((res) => {
        const { alarms } = res.data;
        if (alarms && alarms.length > 0) {
          setAlarmParametername(alarms[0].paramName);
          const options = alarms.map((d) => ({
            label: d.paramName,
            value: d.paramId,
          }));
          const allOption = { label: "All", value: "all" };
          options.unshift(allOption);
          setAlarmParameters(options);
          setSelectedAlarmParamFilter(options[0]);
        } else {
          setAlarmParametername("");
          setAlarmParameters([]);
          setSelectedAlarmParamFilter(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching alarm parameters:", error);
        // Handle error state if needed
      });
  };

  useEffect(() => {
    const patientid = secureLocalStorage.getItem("PatientToView");
    setPatientId(patientid);
    loadPatientDetailsById(patientid);
    loadPhysicianDetailsById(patientid);
    loadDeviceDetailsById(patientid);
    loadWaveformParameters(patientid);
    loadVitalsParameters(patientid);
    loadMeasurementDetailsById(patientid);
    // fetchImages(patientid);
    loadPatientHistory(patientid);
    // loadAlarmHistory(patientid);
    loadAlarmParameters(patientid);
    loadAllPatientImages();
  }, []);

  const fetchImages = (patientId) => {
    ApiCalls.getdetails(
      "patient/" + patientId + "/downloadFilesByPatientId"
    ).then((response) => {
      console.log(response.data);
      if (response.data != undefined) {
        setImages(response.data);
      }
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleOpenLimitFilters = (e) => {
    setOpenLimitFilter(e.currentTarget);
  };

  const handleCloseLimitFilters = () => {
    setOpenLimitFilter(null);
  };

  const handleLimitFilterChange = (filter) => {
    setSelectedLimitFilter(filter);
    getWaveformData(patientId, filter.value, selectedWaveParamFilter.label);
    handleCloseLimitFilters();
  };

  const handleRefresh = () => {
    getWaveformData(
      patientId,
      selectedLimitFilter.value,
      selectedWaveParamFilter.label
    );
  };

  // Function to format date according to the user's local timezone in 'dd-mm-yyyy hh:mm:ss AM/PM' format
  const formatToLocalTime = (timestamp) => {
    const date = new Date(timestamp);

    // Extract date parts
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();

    // Extract time parts
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Pad hours with leading zero if needed
    const formattedHours = String(hours).padStart(2, "0");

    // Combine parts into desired format with AM/PM
    return `${day}-${month}-${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };

  let userFeed = waveformMeasurements.map(function (measurement, index) {
    // Format the measurement timestamp to the local timezone with AM/PM
    const formattedTime = formatToLocalTime(measurement.actualtimestamp);

    const row = {
      measureId: measurement.measurementid,
      devicename: measurement.deviceName,
      param_name: measurement.param_name,
      meastime: formattedTime, // Use formatted time here
    };
    return row;
  });

  const data = {
    columns: [
      {
        label: "Measurement Id",
        field: "measureId",
      },
      {
        label: secureLocalStorage.getItem("DeviceName") + " Name",
        field: "devicename",
      },
      {
        label: "Parameter",
        field: "param_name",
      },
      {
        label: "Measured Timestamp",
        field: "meastime",
      },
    ],
    rows: userFeed,
  };

  const handleUploadClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPatient(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!imageType) {
        setImageTypeError(true);
        setError("Please select an image type.");
        return;
      }

      // Create a FormData object to hold the file data and other information
      const formData = new FormData();
      formData.append("file", file);
      formData.append("imageType", imageType);

      // Send the form data to the server
      ApiCalls.storeFile("uploadFileToServer", formData)
        .then((response) => {
          const { status } = response.data;
          if (status.message === "Success") {
            console.log("Image uploaded successfully:", status.details);

            // Extract the filePath from the response
            const filePath = status.filePath;
            setFilePath(filePath);
            let viewPath = "";

            // Determine the API path based on imageType
            if (imageType === "standard") {
              viewPath = "viewFile";
            } else if (imageType === "dicom") {
              viewPath = "viewDicomImage";
            }

            // View the file using the appropriate endpoint
            ApiCalls.viewFile(viewPath, { filePath }, { responseType: "blob" })
              .then((response) => {
                // Convert the response to a Blob and create a URL
                const blob = new Blob([response.data], { type: "image/png" });
                const url = window.URL.createObjectURL(blob);
                setImage(url); // Set the image URL to display
                setSelectedFile(file); // Optional: store the file if needed

                // If the imageType is "dicom", call readDicomDetails
                if (imageType === "dicom") {
                  readDicomDetails(filePath);
                }
              })
              .catch((error) => {
                console.error(`Error in ${viewPath} API:`, error);
                // Handle error, e.g., show an error message
              });
          } else if (status.details === "File already exists") {
            swal("File Already Exists", {
              icon: "info",
            });
          } else {
            console.error("Upload failed:", status.details);
            // Handle error, e.g., show an error message
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          // Handle error, e.g., show an error message
        });
    }
  };

  const readDicomDetails = (filePath) => {
    // API call to read DICOM details
    ApiCalls.getdetails(`readdicom?filePath=${encodeURIComponent(filePath)}`)
      .then((response) => {
        console.log(response.data);
        // Update the state with the data from the API
        setHmrNo(response.data.patientId || "");
        setName(response.data.firstName || "");
        setDob(
          response.data.scanDate
            ? moment(response.data.scanDate, "YYYYMMDD").format("YYYY-MM-DD")
            : ""
        );
        setGender(""); // Gender information is not provided in the response, so we leave it blank
        // setImageType(data.imageType || '');
        setScanType(response.data.scanType || "");
        setImageDescription(response.data.imageDescription || "");
        setBodySite(response.data.bodySite || "");
      })
      .catch((error) => {
        console.error("Error reading DICOM details:", error);
      });
  };

  const handleRadioChange = (event) => {
    setImageType(event.target.value);
    setError(""); // Clear error when an image type is selected
  };

  const handleCancel = () => {
    // Implement cancel functionality here
    setOpenDialog(false);
    setSelectedFile(null);
    setName("");
    setHmrNo("");
    setDob("");
    setGender("");
    setPatientIdError(false);
    setNameError(false);
    setDobError(false);
    setImageTypeError(false);
    setFilePathError(false);
  };

  const handleSaveDetails = () => {
    // Collect validation errors
    const errors = {};

    if (!patientDetails.patientId) {
      errors.patientId = true;
    }

    if (!patientDetails.firstName) {
      errors.name = true;
    }

    if (!patientDetails.dob) {
      errors.dob = true;
    }

    if (!imageType) {
      errors.imageType = true;
      setError("Please select an image type.");
    }

    if (!filePath) {
      errors.filePath = true;
    }

    // Display errors if any
    if (Object.keys(errors).length > 0) {
      // Set all errors at once
      setPatientIdError(errors.patientId || false);
      setNameError(errors.name || false);
      setDobError(errors.dob || false);
      setImageTypeError(errors.imageType || false);
      setFilePathError(errors.filePath || false);
      return; // Stop further execution
    } else {
      if (
        imageType === "dicom" &&
        hmrNo &&
        patientDetails.patientId !== hmrNo
      ) {
        swal(
          `${secureLocalStorage.getItem("PatientName")} ID Mismatch`,
          `The ${secureLocalStorage.getItem("PatientName")} ID from the DICOM image does not match the current patient's ID.`,
          {
            icon: "error",
          }
        );
        return; // Stop further execution
      }

      // Implement save details functionality here

      let tenantId = secureLocalStorage.getItem("tenantId");
      let companyId = secureLocalStorage.getItem("compId");
      let physicianId = "";
      const rolename = secureLocalStorage.getItem("rolename");

      if (rolename === "Tenant") {
        tenantId = secureLocalStorage.getItem("UUID");
        companyId = "0";
        physicianId = "0";
      } else if (rolename === "Company") {
        tenantId = secureLocalStorage.getItem("tenantId");
        companyId = secureLocalStorage.getItem("UUID");
        physicianId = "0";
      } else if (rolename === "Physician") {
        tenantId = secureLocalStorage.getItem("tenantId");
        companyId = secureLocalStorage.getItem("compId");
        physicianId = secureLocalStorage.getItem("UUID");
      }

      // Define the data to be sent in the POST request
      const requestBody = {
        tenantId: tenantId,
        companyId: companyId,
        physicianId: physicianId,
        patientId: patientDetails.patientId,
        firstName: name,
        dob: dob,
        imageType: imageType,
        scanType: scanType,
        filePath: filePath,
        imageDescription: imageDescription,
        bodySite: bodySite,
      };

      // Make the POST request to the API
      console.log(requestBody);

      ApiCalls.register("saveImages", requestBody)
        .then((response) => {
          console.log(response.data);
          if (response.data.status.message === "Success") {
            swal(
              `${secureLocalStorage.getItem(
                "PatientName"
              )} Image Uploaded Successfully`,
              {
                icon: "success",
              }
            );
            handleCancel();
            loadAllPatientImages();
          } else {
            console.log(
              `${secureLocalStorage.getItem("PatientName")} Image Upload Failed`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleViewImage = (imageType, filePath) => {
    setOpenImageViewDialog(true);
    setImageType(imageType);

    if (imageType === "standard") {
      viewStandardFile(filePath);
    } else if (imageType === "dicom") {
      viewDicomFile(filePath);
    }
  };

  const viewStandardFile = (filePath) => {
    ApiCalls.viewFile("viewFile", { filePath })
      .then((response) => {
        if (response.data instanceof Blob) {
          const url = window.URL.createObjectURL(response.data);
          // console.log("Object URL:", url);
          setImageURL(url); // Set the URL to display the image

          // Optionally, you can also read the Blob and convert it to Base64 if needed
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64Image = reader.result;
            // console.log("Base64 Image URL:", base64Image);
            // You can use Base64 if needed, otherwise, the object URL should work
          };
          reader.readAsDataURL(response.data);
        } else {
          console.error("Unexpected response data type:", typeof response.data);
        }
      })
      .catch((error) => {
        console.error("Error in viewFile API:", error);
        // Handle error, e.g., show an error message
      });
  };

  const viewDicomFile = (filePath) => {
    ApiCalls.viewFile("viewDicomImage", { filePath })
      .then((response) => {
        if (response.data instanceof Blob) {
          console.log(response.data);
          setDicomFile(response.data); // Set the Blob to display in DicomViewer
        } else {
          console.error("Unexpected response data type:", typeof response.data);
        }
      })
      .catch((error) => {
        console.error("Error in viewDicomImage API:", error);
        // Handle error, e.g., show an error message
      });
  };

  const handleCloseImageViewDialog = () => {
    setOpenImageViewDialog(false);
    setImageURL("");
    setDicomFile(null);
  };

  const handleOpenDiComViewer = () => {
    setOpenDiComViewer(true);
  };

  const handleCloseDiComViewer = () => {
    setOpenDiComViewer(false);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale * 1.2);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => prevScale / 1.2);
  };

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const handleReset = () => {
    setScale(1);
    setRotation(0);
    setFlipHorizontal(false);
    setFlipVertical(false);
  };

  const handleFlipHorizontal = () => {
    setFlipHorizontal((prevFlip) => !prevFlip);
  };

  const handleFlipVertical = () => {
    setFlipVertical((prevFlip) => !prevFlip);
  };

  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container sx={{ marginTop: 10 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard">
              <Button
                variant="text"
                startIcon={<Home fontSize="small" />}
                onMouseDown={handleMouseDown}
                sx={{ mr: 1 }}
              >
                Home
              </Button>
            </Link>

            <Link to="/managepatient">
              <Button
                variant="text"
                startIcon={
                  <Icon
                    icon={"material-symbols:recent-patient-rounded"}
                    fontSize="small"
                  />
                }
                onMouseDown={handleMouseDown}
                sx={{ mr: 1 }}
              >
                {"Manage " + secureLocalStorage.getItem("PatientName")}
              </Button>
            </Link>

            <StyledBreadcrumb
              label={secureLocalStorage.getItem("PatientName") + " View"}
            />
          </Breadcrumbs>
        </Stack>

        <Card>
          <CardHeader
            title={
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                onMouseDown={handleMouseDown}
                aria-label="icon label tabs example"
              >
                <Tab
                  icon={<Icon icon={"solar:user-id-bold"} width="30" />}
                  label="General Details"
                  sx={{ fontSize: 18 }}
                />
                {secureLocalStorage.getItem("rolename") !== "Physician" && (
                  <Tab
                    icon={<Icon icon={"fa6-solid:user-doctor"} width="30" />}
                    label={
                      secureLocalStorage.getItem("PhysicianName") + " Details"
                    }
                    wrapped
                    sx={{ fontSize: 18 }}
                  />
                )}
                <Tab
                  icon={<Icon icon={"tabler:devices-pin"} width="30" />}
                  label={secureLocalStorage.getItem("DeviceName") + " Details"}
                  sx={{ fontSize: 18 }}
                />
                {patientTrend === "false" ||
                  secureLocalStorage.getItem("rolename") === "Company" ? null : (
                  <Tab
                    icon={<Icon icon={"fa-solid:chart-bar"} width="30" />}
                    label="Measurement Details"
                    wrapped
                    sx={{ fontSize: 18 }}
                  />
                )}
                <Tab
                  icon={<Icon icon={"fluent:history-16-filled"} width="30" />}
                  label="History"
                  wrapped
                  sx={{ fontSize: 18 }}
                />
                {/* {secureLocalStorage.getItem("rolename") === "Physician" ? (
                  <Tab
                    icon={
                      <Icon icon={"material-symbols:view-list"} width="30" />
                    }
                    label="View Diagnostics"
                    wrapped
                    sx={{ fontSize: 18 }}
                  />
                ) : null} */}
              </Tabs>
            }
          />

          <CardContent>
            <TabPanel value={tabValue} index={0}>
              {/* General Details Tab - remains unchanged */}
              {loadPatientDetails ? ( // Display loading component when loadPatientDetails is true
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Skeleton variant="rounded" width={200} height={200} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <Box sx={{ mb: 2 }}>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>

                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Skeleton variant="text" width="50%" />
                        <Skeleton variant="text" width="70%" />
                      </Box>

                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Skeleton variant="text" width="40%" />
                        <Skeleton variant="text" width="60%" />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              ) : (
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Avatar
                        variant="rounded"
                        alt={
                          patientDetails.firstName +
                          " " +
                          patientDetails.lastName
                        }
                        src={patientDetails.image}
                        sx={{ width: 200, height: 200, objectFit: "contain" }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h5" sx={{ color: "text.primary" }}>
                          {(patientDetails.firstName
                            ? patientDetails.firstName
                            : "") +
                            (patientDetails.lastName
                              ? " " + patientDetails.lastName
                              : "") || "--"}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.secondary" }}
                        >
                          {patientDetails.email}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.secondary" }}
                        >
                          Gender
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.primary" }}
                        >
                          {patientDetails.gender !== "null"
                            ? patientDetails.gender
                            : "--"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.secondary" }}
                        >
                          DOB
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.primary" }}
                        >
                          {/* {patientDetails.dob} */}
                          {moment(patientDetails.dob)
                            .utc()
                            .format("DD-MM-YYYY")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Phone
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.primary" }}
                          >
                            {patientDetails.phone}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.primary" }}
                          >
                            {patientDetails.address &&
                              Object.keys(patientDetails.address).length > 0 ? (
                              <>
                                {patientDetails.address.city &&
                                  patientDetails.address.state &&
                                  patientDetails.address.country ? (
                                  ` ${patientDetails.address.state}, ${patientDetails.address.country}`
                                ) : (
                                  <>
                                    {patientDetails.address.city &&
                                      patientDetails.address.state ? (
                                      `${patientDetails.address.city}, ${patientDetails.address.state}`
                                    ) : (
                                      <>
                                        {patientDetails.address.city || ""}
                                        {patientDetails.address.city &&
                                          patientDetails.address.state &&
                                          patientDetails.address.country
                                          ? ", "
                                          : ""}
                                        {patientDetails.address.state || ""}
                                        {patientDetails.address.state &&
                                          patientDetails.address.country
                                          ? ", "
                                          : ""}
                                        {patientDetails.address.country || ""}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              "Not Provided"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </TabPanel>

            {secureLocalStorage.getItem("rolename") !== "Physician" && (
              <TabPanel value={tabValue} index={1}>
                {/* Physician Details Tab - remains unchanged */}
                {physicianDetails.length > 0 ? (
                  <Grid container spacing={3}>
                    {physicianDetails.map((physician) => (
                      <Grid item xs={12} sm={6} md={6}>
                        <Card
                          key={physician.physicianId}
                          sx={{
                            boxShadow: 3,
                            px: 2,
                            py: 3,
                            minHeight: 200,
                            backgroundColor: "#F4F6F8",
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  variant="rounded"
                                  alt={
                                    physician.firstName +
                                    " " +
                                    physician.lastName
                                  }
                                  src={physician.image}
                                  sx={{
                                    width: 120,
                                    height: 120,
                                    objectFit: "contain",
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    ml: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      mb: 2,
                                      ml: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Name :
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {physician.firstName +
                                        " " +
                                        physician.lastName}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      mb: 2,
                                      ml: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Email
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {physician.email}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      mb: 2,
                                      ml: 2,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.secondary" }}
                                    >
                                      Phone
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {physician.phone}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            }
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <center>
                    No {secureLocalStorage.getItem("PhysicianName")} Assigned
                  </center>
                )}
              </TabPanel>
            )}

            <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician" ? 1 : 2
              }
            >
              {/* Device Details Tab - remains unchanged */}
              {deviceDetails.length > 0 ? (
                <Grid container spacing={3}>
                  {deviceDetails.map((device) => (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Card
                        key={device.deviceId}
                        sx={{
                          boxShadow: 3,
                          px: 2,
                          py: 3,
                          minHeight: 200,
                          backgroundColor: "#F4F6F8",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                variant="rounded"
                                alt={device.devicename}
                                src={
                                  device.deviceimageName
                                    ? device.deviceimageName
                                    : DeviceImg
                                }
                                sx={{
                                  width: 120,
                                  height: 120,
                                  objectFit: "contain",
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  ml: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    mb: 2,
                                    ml: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    {secureLocalStorage.getItem("DeviceName")}{" "}
                                    Name
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.primary" }}
                                  >
                                    {device.devicename}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    mb: 2,
                                    ml: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    UUID
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.primary" }}
                                  >
                                    {device.randomDeviceId}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    mb: 2,
                                    ml: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    Model Name
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ color: "text.primary" }}
                                  >
                                    {device.modelName === undefined
                                      ? " "
                                      : device.modelName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <center>
                  No {secureLocalStorage.getItem("DeviceName")} Assigned
                </center>
              )}
            </TabPanel>

            {secureLocalStorage.getItem("rolename") !== "Company" &&
              patientTrend !== "false" && (
                <TabPanel
                  value={tabValue}
                  index={
                    secureLocalStorage.getItem("rolename") === "Physician"
                      ? 2
                      : 3
                  }
                >
                  {/* Measurement Details Tab - remains unchanged */}
                  <Grid item xs={12} sm={12} md={12}>
                    <Card
                      sx={{
                        boxShadow: 5,
                        textAlign: "left",
                      }}
                    >
                      <CardHeader
                        title="Graphical Display"
                        action={
                          <>
                            <Tooltip title="Filter Parameter">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={handleOpenParamFilters}
                                endIcon={
                                  <Iconify icon="material-symbols:filter-alt" />
                                }
                              >
                                {selectedParamFilter.label}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Filter Date">
                              <Button
                                className="ml-2"
                                color="primary"
                                variant="contained"
                                onClick={handleOpenDateFilters}
                                endIcon={
                                  <Iconify icon="material-symbols:filter-list-rounded" />
                                }
                              >
                                {selectedDateFilter.label}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Select Duration">
                              <Button
                                hidden={selectedDateFilter.value !== "today"}
                                className="ml-2 mr-2"
                                color="primary"
                                variant="contained"
                                onClick={handleOpenDurationFilters}
                                endIcon={<Iconify icon="ph:timer-fill" />}
                              >
                                {selectedDurationFilter.label}
                              </Button>
                            </Tooltip>
                          </>
                        }
                      />
                      <CardContent>
                        <Box
                          sx={{ pb: 1, px: 2, borderRadius: "10px", backgroundColor: "#27293d", height: 350 }}
                          dir="ltr"
                        >
                          <canvas ref={canvasRef} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Popover
                    open={Boolean(openDurationFilter)}
                    anchorEl={openDurationFilter}
                    onClose={handleCloseDurationFilters}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        "& .MuiMenuItem-root": {
                          px: 1,
                          typography: "body2",
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <Stack spacing={0.75}>
                      {durationFilterOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          selected={
                            option.value === selectedDurationFilter.value
                          }
                          onClick={() => handleDurationFilterChange(option)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Stack>
                  </Popover>

                  <Popover
                    open={Boolean(openDateFilter)}
                    anchorEl={openDateFilter}
                    onClose={handleCloseDateFilters}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        "& .MuiMenuItem-root": {
                          px: 1,
                          typography: "body2",
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <Stack spacing={0.75}>
                      {filterOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          selected={option.value === selectedDateFilter.value}
                          onClick={() => handleDateFilterChange(option)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Stack>
                  </Popover>

                  <Popover
                    open={Boolean(openParamFilter)}
                    anchorEl={openParamFilter}
                    onClose={handleCloseParamFilters}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: "auto", // Change from fixed width to auto
                        maxWidth: "300px", // Set a maximum width
                        "& .MuiMenuItem-root": {
                          px: 1,
                          typography: "body2",
                          borderRadius: 0.75,
                          whiteSpace: "normal", // Allow text to wrap
                          wordBreak: "break-word", // Break long words if necessary
                        },
                      },
                    }}
                  >
                    <Stack spacing={0.75}>
                      {vitalParameters.map((option) => (
                        <MenuItem
                          key={option.value}
                          selected={option.value === selectedParamFilter.value}
                          onClick={() => handleParamFilterChange(option)}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Stack>
                  </Popover>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      mt: 2,
                    }}
                  >
                    <MeasurementList
                      title="Measurement Data"
                      measurements={vitalMeasurements}
                      setMeasurements={setVitalMeasurements}
                      patientId={patientId}
                      loading={vitalLoading}
                      setLoading={setVitalLoading}
                      onRefresh={chartMeasurements}
                      selectedDurationFilterDash={selectedDurationFilter}
                      selectedDateFilterDash={selectedDateFilter}
                      selectedParamFilterDash={selectedParamFilter}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      mt: 2,
                      display:
                        secureLocalStorage.getItem("RoleName") === "Tenant" ||
                          patientTrend === "false"
                          ? "none"
                          : "",
                    }}
                  >
                    <Card>
                      <CardHeader
                        title={
                          <Typography variant="h5" gutterBottom>
                            Waveform Data
                          </Typography>
                        }
                        action={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* Tooltip: Filter Parameter */}
                            <Tooltip title="Filter Parameter">
                              <Button
                                color="primary"
                                className="ml-2"
                                variant="contained"
                                onClick={handleOpenWaveParamFilters}
                                endIcon={<Icon icon="mi:filter" />}
                              >
                                {selectedWaveParamFilter.label}
                              </Button>
                            </Tooltip>

                            <Popover
                              open={Boolean(openWaveParamFilter)}
                              anchorEl={openWaveParamFilter}
                              onClose={handleCloseWaveParamFilters}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  mt: 1.5,
                                  ml: 0.75,
                                  width: 180,
                                  "& .MuiMenuItem-root": {
                                    px: 1,
                                    typography: "body2",
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <Stack spacing={0.75}>
                                {waveformParameters.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    selected={
                                      option.value ===
                                      selectedWaveParamFilter.value
                                    }
                                    onClick={() =>
                                      handleWaveParamFilterChange(option)
                                    }
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Stack>
                            </Popover>

                            {/* Tooltip: Filter Limit */}
                            <Tooltip title="Filter Limit">
                              <Button
                                color="primary"
                                variant="contained"
                                className="ml-2"
                                onClick={handleOpenLimitFilters}
                                endIcon={
                                  <Icon icon="material-symbols:filter-alt" />
                                }
                              >
                                {selectedLimitFilter.label}
                              </Button>
                            </Tooltip>

                            {/* Tooltip: Refresh */}
                            <Tooltip title="Refresh">
                              <Fab
                                size="small"
                                color="primary"
                                className="ml-2 mr-2"
                                onMouseDown={handleMouseDown}
                                onClick={handleRefresh}
                              >
                                <Icon icon="mdi:refresh" width="25" />
                              </Fab>
                            </Tooltip>

                            {/* Popover Component */}
                            <Popover
                              open={Boolean(openLimitFilter)}
                              anchorEl={openLimitFilter}
                              onClose={handleCloseLimitFilters}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  mt: 1.5,
                                  ml: 0.75,
                                  width: 180,
                                  "& .MuiMenuItem-root": {
                                    px: 1,
                                    typography: "body2",
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <Stack spacing={0.75}>
                                {limitfilterOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    selected={
                                      option.value === selectedLimitFilter.value
                                    }
                                    onClick={() =>
                                      handleLimitFilterChange(option)
                                    }
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Stack>
                            </Popover>
                          </Box>
                        }
                      />
                      <CardContent>
                        {waveLoading ? (
                          <MDBTable
                            striped
                            borderless
                            hover
                            responsive
                            style={{ marginTop: 15 }}
                          >
                            <MDBTableHead color="primary-color" textWhite>
                              <tr>
                                <th>Measurement Id</th>
                                <th>
                                  {secureLocalStorage.getItem("DeviceName")}
                                </th>
                                <th>Parameter</th>
                                <th>Measured @</th>
                                <th>Reading</th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              <tr>
                                <td colSpan="5">
                                  <center>
                                    <CircularProgress />
                                  </center>
                                </td>
                              </tr>
                            </MDBTableBody>
                          </MDBTable>
                        ) : (
                          <MDBDataTable
                            theadColor="primary-color"
                            borderless
                            entriesOptions={[5, 10, 20, 50, 100]}
                            fullpagination="true"
                            entries={5}
                            tag
                            dark={false}
                            noBottomColumns
                            responsive
                            data={data}
                            processing={waveLoading}
                            striped
                            btn={true}
                            sortable={false}
                            noRecordsFoundLabel={
                              <center>No Waveform Data Found</center>
                            }
                          />
                        )}
                      </CardContent>
                      <CardActions
                        sx={{
                          float: "right",
                        }}
                      >
                        {/* Button: Total Records */}
                        <Button
                          className="ml-2"
                          color="primary"
                          variant="outlined"
                          onMouseDown={handleMouseDown}
                          sx={{
                            cursor: "text",
                          }}
                        >
                          {waveLoading ? (
                            <Icon
                              icon={"eos-icons:three-dots-loading"}
                              width="30"
                            />
                          ) : waveformMeasurements[0]?.dataCount ? (
                            `Total Records: ${waveformMeasurements[0].dataCount}`
                          ) : null}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </TabPanel>
              )}

            <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician"
                  ? 3
                  : secureLocalStorage.getItem("rolename") === "Company"
                    ? 3
                    : patientTrend === "false"
                      ? 3
                      : 4
              }
            >
              {/* History Tab - remains unchanged */}
              {patientHistory.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Ward No</TableCell>
                        <TableCell>Bed No</TableCell>
                        <TableCell>Admitted On</TableCell>
                        <TableCell>Discharged On</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patientHistory.map((patient) => (
                        <TableRow key={`${patient.bedId}-${patient.mapStatus}`}>
                          <TableCell>
                            {patient.mapStatus === "Admitted" ? (
                              <Avatar
                                sx={{
                                  bgcolor: (theme) =>
                                    theme.palette["success"].main,
                                }}
                              >
                                <Icon
                                  icon={"material-symbols:inpatient-rounded"}
                                />
                              </Avatar>
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: (theme) =>
                                    theme.palette["error"].main,
                                }}
                              >
                                <Icon
                                  icon={"material-symbols:outpatient-rounded"}
                                />
                              </Avatar>
                            )}
                            <Typography
                              variant="body2"
                              sx={{
                                marginTop: 1,
                                color:
                                  patient.mapStatus === "Admitted"
                                    ? (theme) => theme.palette["success"].darker
                                    : (theme) => theme.palette["error"].darker,
                              }}
                            >
                              {patient.mapStatus === "Admitted"
                                ? "Admitted"
                                : "Discharged"}
                            </Typography>
                          </TableCell>
                          <TableCell>{patient.wardNo}</TableCell>
                          <TableCell>{patient.bedNo}</TableCell>
                          <TableCell>
                            {new Date(Number(patient.admittedTime))
                              .toLocaleString("en-US", {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                              .replace(",", "")}
                          </TableCell>
                          <TableCell>
                            {patient.mapStatus === "Discharged" &&
                              new Date(Number(patient.dischargeTime))
                                .toLocaleString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })
                                .replace(",", "")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <center>No History</center>
              )}
            </TabPanel>

            {/* <TabPanel
              value={tabValue}
              index={
                secureLocalStorage.getItem("rolename") === "Physician" ? 4 : 5
              }
            >
              <Card>
                <CardHeader
                  title={
                    <Typography variant="h4" gutterBottom>
                      {"Image List"}
                    </Typography>
                  }
                  action={
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="formkit:upload" />}
                        onMouseDown={handleMouseDown}
                        onClick={handleUploadClick}
                      >
                        Upload Image
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="ic:baseline-view-list" />}
                        onClick={handleOpenDiComViewer}
                        onMouseDown={handleMouseDown}
                      >
                        DiCom Viewer
                      </Button>
                    </Stack>
                  }
                />
                <CardContent>
                  {loading ? (
                    <center>
                      <CircularProgress />
                    </center>
                  ) : patients.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}>Date and Time</TableCell>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}>Image Type</TableCell>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}>Scan Type</TableCell>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}>Image Description</TableCell>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}>Body Part Scan</TableCell>
                            <TableCell sx={{ backgroundColor: "#e0e0e0", textAlign: "center" }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {patients
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((patient, index) => (
                              <TableRow
                                key={patient.imageId}
                                sx={{
                                  backgroundColor: index % 2 === 0 ? "#f4f4f4" : "inherit",
                                }}
                              >
                                <TableCell sx={{ textAlign: "center" }}>
                                  {moment(patient.scanDate).format("YYYY-MM-DD HH:mm") || "N/A"}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {patient.imageType || "N/A"}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {patient.scanType || "N/A"}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {patient.imageDescription || "N/A"}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {patient.bodySite || "N/A"}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onMouseDown={handleMouseDown}
                                    onClick={() => handleViewImage(patient.imageType, patient.filePath)} // Adjust the function and parameter as needed
                                    style={{ marginTop: 10 }} // Add margin at the bottom for better spacing
                                  >
                                    View Image
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <center>No Details Found</center>
                  )}
                </CardContent>
              </Card>
            </TabPanel> */}

          </CardContent>

          <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Image Details"}
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <Paper
                  elevation={3}
                  style={{ padding: 20, maxWidth: "800px", width: "100%" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Image Type
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        value={imageType}
                        onChange={handleRadioChange}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <FormControlLabel
                            value="standard"
                            control={<Radio />}
                            label="Standard"
                          />
                          <FormControlLabel
                            value="dicom"
                            control={<Radio />}
                            label="DiCom"
                          />
                        </div>
                      </RadioGroup>
                      {imageTypeError && (
                        <Typography color="error" variant="body2">
                          {error}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <TextField
                        name="patientId"
                        label={
                          <span>
                            {secureLocalStorage.getItem("PatientName") + " Id"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        value={patientDetails.patientId}
                        // onChange={handleInputChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          style: { height: "50px" },
                          readOnly: true,
                        }}
                        style={{ marginBottom: "22px" }}
                        error={patientIdError}
                        helperText={
                          patientIdError &&
                          `${secureLocalStorage.getItem(
                            "PatientName"
                          )} Id is required`
                        }
                      />
                      <TextField
                        name="name"
                        label={
                          <span>
                            {secureLocalStorage.getItem("PatientName") +
                              " Name"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        value={
                          patientDetails.firstName || patientDetails.lastName
                            ? `${patientDetails.firstName || ""} ${patientDetails.lastName || ""
                              }`.trim()
                            : "No Name"
                        }
                        // onChange={handleInputChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          style: { height: "50px" },
                          readOnly: true,
                        }}
                        style={{ marginBottom: "22px" }}
                        error={nameError}
                        helperText={
                          nameError &&
                          `${secureLocalStorage.getItem(
                            "PatientName"
                          )} Name is required`
                        }
                      />
                      <TextField
                        id="date"
                        name="dob"
                        label={
                          <span>
                            DOB <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        type="date"
                        value={patientDetails.dob}
                        // onChange={handleInputChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: maxDate,
                        }}
                        error={dobError}
                        helperText={dobError && `DOB Id is required`}
                      />
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: "22px", mt: 2 }}
                      >
                        <InputLabel>Gender</InputLabel>
                        <Select
                          name="gender"
                          value={patientDetails.gender}
                          onChange={(e) => setGender(e.target.value)}
                          label="Gender"
                          inputProps={{
                            readOnly: true,
                          }}
                        >
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selectedFile && imageType === "dicom" ? (
                        <DiComViewer file={selectedFile} />
                      ) : (
                        <Avatar
                          alt="Image"
                          variant="rounded"
                          src={
                            selectedFile
                              ? URL.createObjectURL(selectedFile)
                              : null
                          }
                          sx={{
                            width: 350,
                            height: 300,
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              cursor: "pointer",
                              transform: "scale(1.1)",
                            },
                          }}
                          onClick={() =>
                            document.getElementById("avatar-input").click()
                          }
                        >
                          <Icon
                            icon="flat-color-icons:add-image"
                            color="#4481eb"
                            width="80"
                          />
                          <input
                            type="file"
                            accept="image/*, .dcm"
                            id="avatar-input"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </Avatar>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSaveDetails}
                        onMouseDown={handleMouseDown}
                      >
                        Save Details
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={handleCancel}
                        onMouseDown={handleMouseDown}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </DialogContent>
          </Dialog>

          <ThemeProvider theme={darkTheme}>
            <Dialog
              fullScreen={false}
              maxWidth="lg"
              open={openImageViewDialog}
              aria-labelledby="responsive-dialog-title"
              sx={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <DialogTitle id="responsive-dialog-title" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6">
                  {"Image Viewer"}
                </Typography>
                <IconButton
                  onClick={handleCloseImageViewDialog}
                  onMouseDown={handleMouseDown}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {imageType === "dicom" ? (
                  dicomFile ? (
                    <DicomViewer file={dicomFile} />
                  ) : (
                    <center>
                      <Icon icon="eos-icons:three-dots-loading" width="100" height="100" />
                    </center>
                  )
                ) : imageURL ? (
                  <img
                    src={imageURL}
                    alt="View"
                    style={{
                      width: "100%",
                      height: "auto",
                      minHeight: 500,
                      minWidth: 500,
                      maxWidth: 700,
                      maxHeight: 600,
                      transition: "all 0.2s ease-in-out",
                      cursor: "pointer",
                      transform: `
                                    rotate(${rotation}deg) 
                                    scale(${scale}) 
                                    scaleX(${flipHorizontal ? -1 : 1}) 
                                    scaleY(${flipVertical ? -1 : 1})
                                `,
                    }}
                    onError={(e) => {
                      console.log("Error loading image:", e);
                    }}
                  />
                ) : (
                  <center>
                    <CircularProgress />
                  </center>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  display: imageType === "standard" ? "block" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "black",
                  py: 1,
                  width: "100%",
                  borderTop: "1px solid #333",
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleZoomIn}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'tabler:zoom-in'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom In</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleZoomOut}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'tabler:zoom-out'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Zoom Out</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleRotateLeft}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'ic:outline-rotate-left'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Left</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleRotateRight}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'ic:outline-rotate-right'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Rotate Right</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleFlipHorizontal}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'mdi:flip-horizontal'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Horizontal</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleFlipVertical}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'mdi:flip-vertical'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Flip Vertical</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onClick={handleReset}
                      sx={{ color: "white" }}
                    >
                      <Icon icon={'bx:reset'} />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: "white" }}>Reset View</Typography>
                  </Box>
                </Box>
              </DialogActions>
            </Dialog>
          </ThemeProvider>

          <DiComViewerComponent
            open={openDiComViewer}
            onClose={handleCloseDiComViewer}
            // seriesImages={seriesImages}
            imageDetails={seriesImages}
            patientDetails={patientDetails}
          />

        </Card>
        <div
          style={{
            height: "50px",
          }}
        />
      </Container>
    </div>
  );
};

export default PatientView;
