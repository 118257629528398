import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

// @mui-components
import { Box, Link } from "@mui/material";

//logo
import iOrbit from "../../assets/iOrbit_Final Logo.png";

//externals
import secureLocalStorage from "react-secure-storage";


// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={iOrbit}
      sx={{ width: 250, height: 90, cursor: "pointer", ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link
      to={
        secureLocalStorage.getItem("isSignedIn") == true
          ? secureLocalStorage.getItem("roleId") === "8"
            ? "/mydashboard"
            : "/dashboard"
          : "/"
      }
      component={RouterLink}
      sx={{ display: "contents" }}
    >
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
